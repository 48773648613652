@media only screen and (min-width: 768px) and (max-width: 1024px) {
   .ipd-visible {
      display: block !important;
   }

   .min-h-40 {
      min-height: 40px;
   }

   .ipd-hidden {
      display: none !important;
   }

   .swiper-horizontal>.swiper-pagination-bullets,
   .swiper-pagination-bullets.swiper-pagination-horizontal,
   .swiper-pagination-custom,
   .swiper-pagination-fraction {
      bottom: 0px !important;
      background: #fff;
   }

   .main-card-hover .imae-container .swiper-pagination-bullet {
      opacity: 1 !important;
   }

   .ipd-px-24 {
      padding-left: 10px;
      padding-right: 10px;
   }

   .ipd-col-7 {
      grid-column: span 5 / span 5;
   }

   .ipd-col-5 {
      grid-column: span 7 / span 7;
   }

   .mob-img-container>span {
      width: 100% !important;
   }

   .min-product-info.active {
      display: none;
   }

   .min-product-info-plp.active {
      display: none;
   }

   .mobile-slider-no-arrow .swiper-button-prev,
   .mobile-slider-no-arrow .swiper-button-next,
   .specifications .swiper-button-prev,
   .specifications .swiper-button-next,
   .buylook .swiper-button-prev,
   .buylook .swiper-button-next {
      display: none !important;
   }

   .ipd-col-span-9 {
      grid-column: span 4 / span 4;
   }

   .ipd-col-span-3 {
      grid-column: span 8 / span 8;
   }

   .pay-btn-section {
      display: none;
   }

   .paymentBox .control input.pay-inpt:checked~.control__indicator .pay-btn-section {
      display: grid;
   }

   .ipad-width-full {
      width: 100%;
   }

   .ipad-padding-y {
      padding: 0 15px;
   }

   .ipad-grid-col-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
   }

   .main-account-grid .col-span-3.tab-list-sm {
      padding-left: 0.5rem;
   }

   .main-account-grid .col-span-3.tab-list-sm a.inline-block {
      font-size: 11px;
   }

   .ipad-padding-bottom {
      padding-bottom: 80px;
   }

   .ipad-br-font .ipad-text-sm {
      font-size: 10px;
   }

   .ipad-top-padding-6 {
      padding-top: 10px;
   }

   .ipd-padding-bottom-10 {
      padding-top: 10px;
   }

   .ipad-h-w {
      width: 2.5rem;
      height: 2.5rem
   }
   .ipad-border-none-pdp{
      border-top: 0 !important;
   }
}